const testimonialData = [
    {
        id: 1,
        name: 'Jane D.',
        location: 'Spokane, WA',
        rating: 5,
        comment: "Critter Quitter came out and addressed our pest problem in no time. We haven't seen a bug since their visit. We're very pleased with their service!"
    },
    {
        id: 2,
        name: 'Carlos M.',
        location: 'Liberty Lake, WA',
        rating: 5,
        comment: "We had a persistent rodent issue in our backyard. Critter Quitter came and professionally handled it. They even followed up to ensure the problem was fully resolved!"
    },
    {
        id: 3,
        name: 'Amelia K.',
        location: 'Cheney, WA',
        rating: 5,
        comment: "The team at Critter Quitter is exceptional! They were courteous, knowledgeable, and very effective. We had a wasp nest that was causing us a lot of trouble, but they removed it safely and efficiently."
    },
    {
        id: 4,
        name: 'Tom L.',
        location: 'Deer Park, WA',
        rating: 5,
        comment: "Critter Quitter provides excellent service. They helped us get rid of a serious ant problem in our kitchen. Very professional and highly recommended!"
    },
    {
        id: 5,
        name: 'Billy W.',
        location: 'Medical Lake, WA',
        rating: 5,
        comment: 'Critter Quitter solved our pest problem in one visit. They even gave us tips on preventing future invasions. Excellent service!'
    },
    {
        id: 6,
        name: 'Linda P.',
        location: 'Post Falls, ID',
        rating: 5,
        comment: 'The Critter Quitter team was prompt, professional, and efficient. They handled our pest issue with great care. We were very impressed!'
    },
    {
        id: 7,
        name: 'Benjamin T.',
        location: 'Coeur d\'Alene, ID',
        rating: 5,
        comment: 'Critter Quitter was able to eradicate our bed bug issue. We have been able to sleep peacefully ever since. Highly recommended!'
    },
    {
        id: 8,
        name: 'Sarah J.',
        location: 'Nine Mile Falls, WA',
        rating: 5,
        comment: 'Thank you, Critter Quitter, for helping us with our rodent problem. We now have peace of mind knowing our home is pest-free.'
    }
]
export default testimonialData;