import React from "react";
import { useForm } from "react-hook-form";
// import emailjs from "emailjs-com";
import "./SchedulePage.css";

const SchedulePage = () => {
  // useEffect(() => {
  //   emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  // }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    reset();
  };

  const isReferred = watch("isReferred"); 

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Contact form">
      <label htmlFor="name">
        Name:
        <input id="name" {...register("name", { required: "Name is required" })} />
        {errors.name && <p>{errors.name.message}</p>}
      </label>
      <br />
      <label htmlFor="address">
        Address:
        <input id="address" {...register("address", { required: "Address is required" })} />
        {errors.address && <p>{errors.address.message}</p>}
      </label>
      <br />
      <label htmlFor="email">
        Email:
        <input id="email" {...register("email", { required: "Email is required" })} />
        {errors.email && <p>{errors.email.message}</p>}
      </label>
      <br />
      <label htmlFor="phone">
        Phone:
        <input id="phone" {...register("phone", { required: "Phone number is required" })} />
        {errors.phone && <p>{errors.phone.message}</p>}
      </label>
      <br />
      <label htmlFor="communication">
        Preferred Method of Communication:
        <select id="communication" {...register("communication", { required: "Communication method is required" })}>
            <option>Text</option>
            <option>Phone</option>
            <option>Email</option>
        </select>
        {errors.communication && <p>{errors.communication.message}</p>}
      </label>
      <br />
      <label htmlFor="services">
        Do you have a package in mind?:
        <select id="services" {...register("services", { required: "Service selection is required" })}>
            <option>Monthly</option>
            <option>Quarterly</option>
            <option>As Needed</option>
        </select>
        {errors.services && <p>{errors.services.message}</p>}
      </label>
      <br />
      <label htmlFor="details">
        How Can We Help?:
        <textarea id="details" {...register("details", { required: "This detail field is required" })} />
        {errors.details && <p>{errors.details.message}</p>}
      </label>
      <br />
      <label htmlFor="isReferred">
        Were you referred by someone?:
        <input id="isReferred" type="checkbox" {...register("isReferred")} />
      </label>
      {isReferred && (
        <label htmlFor="referrer">
          Who referred you?:
          <input id="referrer" {...register("referrer", { required: "Referrer is required when referred is checked" })} />
          {errors.referrer && <p>{errors.referrer.message}</p>}
        </label>
      )}
      <br />
      <button type="submit">Submit</button>
    </form>
  );
};

export default SchedulePage;

