import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardText, Button, Row, Col, Container } from 'reactstrap';
import PackageInfoModal from '../../components/PackageInfoModal/PackageInfoModal';  // import the modal component
import './PackagePage.css';

const PackagePage = () => {
  const [modal, setModal] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(null);

  const toggle = () => setModal(!modal);

  const packages = {
    'Monthly': {
      title: 'Monthly',
      info: 'Comprehensive Pest Protection',
      benefits: 'Our monthly service is designed for the toughest pests. Offering the most comprehensive coverage and service, it\'s perfect for those who demand the best protection available.',
      health: 'Regular pest control reduces disease risks by curbing disease-carrying pests, diminishes allergies by managing allergen-producing insects, and promotes food safety by keeping pests out of food sources. It also contributes to better sleep by controlling pests like bedbugs and supports overall mental well-being by maintaining a pest-free environment.'
    },

    'Quarterly': {
      title: 'Quarterly',
      info: 'Seasonal Pest Shield',
      benefits: 'This package is perfect for those wanting to protect their home from pests most common during each season, with the convenience of only four services per year.',
      health: "This service reduces seasonal disease risks by managing pests prevalent in each quarter. It helps limit allergen sources, maintaining a healthier home environment. By keeping your space pest-free, it promotes mental well-being and ensures a peaceful night's sleep year-round."
    },
    'As Needed': {
      title: 'As Needed',
      info: 'Custom Pest Control',
      benefits: 'Our flexible plan is perfect for those who need services on an as-needed basis. This package provides the freedom to call on us whenever pests are a problem.',
      health: 'Even occasional pest control significantly reduces pest populations, limiting disease risks and allergen sources. It provides peace of mind by managing sudden pest issues, contributing to better sleep and overall mental well-being.'
    }
  }

  const openModal = (pkg) => {
    setCurrentPackage(packages[pkg]);
    toggle();
  }

  return (
    <Container className="my-5">
      <Row className="mb-5">
        <Col xs={12}>
          <h1 className="text-center">Our Packages</h1>
        </Col>
      </Row>
      <Row>
        {Object.keys(packages).map((key, index) => (
          <Col xs={12} md={4} key={index}>
            <Card className="mb-4 shadow">
              <CardHeader tag="h3" className="text-center">{packages[key].title}</CardHeader>
              <CardBody>
                <CardTitle tag="h5">{packages[key].info}</CardTitle>
                <CardText>{packages[key].benefits}</CardText>
                <Button color="primary" onClick={() => openModal(key)}>Learn More</Button>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      {currentPackage &&
        <PackageInfoModal
          modal={modal}
          toggle={toggle}
          title={currentPackage.title}
          body={currentPackage.health}
        />
      }
    </Container>
  );
}

export default PackagePage;
