import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import "./About.css";
import Teamwork from "../../images/Teamwork.webp";
import Equipment from "../../images/Equipment.webp";

const About = () => {
  return (
    <Container className="my-5">
      <Row className="mb-5">
        <Col xs={12}>
          <h1 className="text-center">About Critter Quiter</h1>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12} md={6}>
          <h2>Our Mission</h2>
          <p>
            Here at Critter Quiter, we're all about "giving pests the pink
            slip!" Our mission is to provide the most effective pest control
            services with an earnest concern for our environment and safety. We
            understand the value of a pest-free home or business and the peace
            of mind it brings. With our commitment to excellence, whether it’s a
            one-time treatment or a long-term service contract, pests will be
            getting their pink slip in no time.
          </p>
          <h2>Our Promise</h2>
          <p>
            We promise to provide you with pest control service that's not only
            effective, but also conscientious. By using eco-friendly and safe
            pest control strategies, we ensure the environment and your family
            are not harmed while we're handing out those pink slips to pests.
            Your satisfaction is our topmost priority, and we'll work tirelessly
            to ensure we exceed your expectations.
          </p>
          <h2>Our Guarantee</h2>
          <p>
            Our guarantee is your satisfaction. If pests return between
            treatments, refusing to accept their pink slip, we'll come back at
            no extra charge. If we're unable to solve your pest problem, we'll
            refund your last service payment as long as you're a customer.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <Card className="mb-4 shadow">
            <CardImg top src={Teamwork} alt="Card image cap" width='500px' height='755px' className="teamwork" />
            <CardBody>
              <CardTitle tag="h5">Teamwork</CardTitle>
              <CardText>
                Our skilled team works together to ensure efficient and thorough
                pest control for all our clients.
              </CardText>
            </CardBody>
          </Card>
          <Card className="shadow">
            <CardImg top className="teamwork" src={Equipment} alt="Card image cap" width='500px' height='333px' />
            <CardBody>
              <CardTitle tag="h5">Quality Equipment</CardTitle>
              <CardText>
                We use only the latest and most effective pest control
                technologies and products on the market.
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
