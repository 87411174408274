import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./PackageInfoModal.css";

const PackageInfoModal = ({ modal, toggle, title, body }) => {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="package-modal-header">
        {title}
      </ModalHeader>
      <ModalBody className="package-modal-body">{body}</ModalBody>
      <ModalFooter className="package-modal-footer">
        <Button
          color="primary"
          className="package-modal-button"
          onClick={toggle}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PackageInfoModal;
