import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import TestimonialSlider from '../../components/TestimonialSlider/TestimonialSlider'
import Rat from '../../images/Rat.webp';
import './Home.css'

const Home = () => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModal(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
  <>
  <Modal isOpen={modal} toggle={() => setModal(!modal)}>
    <ModalHeader className='modal-header' toggle={() => setModal(!modal)}>Critter Quiter For Sale</ModalHeader>
    <ModalBody>
      This business is not yet in operation. The site, branding and domain (www.critterquiter.com) are available for sale from <a href="https://www.reactprosolutions.com" target='blank'>React Pro Solutions</a>. The site can be customized for the buyer.
    </ModalBody>
  </Modal>
  
  <TestimonialSlider />
  <h1 className="text-center">Welcome to Critter Quiter!</h1>
  <Container className="home-container">
    
            <Row>
                <Col xs="12" md="6">
                    
                    <p>
                        We're committed to helping you live a pest-free life. Whether you're dealing with rodents, 
                        insects, or other unwanted guests, our team of professionals is here to give pests the pink slip.
                    </p>
                </Col>
                <Col xs="12" md="6">
                    <img className="home-img" src={Rat} alt="Rat climbing out of a board" width='500px' height='333px' />
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <h2>Our Services</h2>
                    <p>
                        From routine pest control to emergency extermination, Critter Quiter is equipped to handle a wide range 
                        of pest issues. We prioritize safe and effective methods, ensuring that your home or business remains a comfortable, 
                        pest-free environment.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col xs="12">
                    <h2>Book an Appointment</h2>
                    <p>
                        Ready to get rid of pests for good? Book an appointment with us today, and we'll send our 
                        professionals right to your doorstep.
                    </p>
                    <div className="text-center">
                    <Link to="/contact">
                        <Button id="btn">Schedule Now</Button>
                    </Link>
                    </div>
                </Col>
            </Row>
        </Container>
  </>
  )
};

export default Home;
